<template>
  <div class="whole">
    <Nav></Nav>
    <div class="buy-con desktop-present">
      <div class="buy-title">
        <div
          class="buy-item"
          :class="{ currenred: flag == 1 }"
          @click="onClickItem(1)"
        >
          已支付列表({{ syncInfo.payedOrder }})
        </div>
        <div
          class="buy-item"
          :class="{ currenred: flag == 2 }"
          @click="onClickItem(2)"
        >
          待审核订单({{ syncInfo.unaprovedOrder }})
        </div>
        <div
          class="buy-item"
          :class="{ currenred: flag == 3 }"
          @click="onClickItem(3)"
        >
          待支付列表({{ syncInfo.needPayOrder }})
        </div>
        <div
          class="buy-item"
          :class="{ currenred: flag == 4 }"
          @click="onClickItem(4)"
        >
          支付中列表({{ syncInfo.processingOrder }})
        </div>
      </div>
      <div class="pager-container">
        <el-pagination
          v-if="flag == 1"
          @current-change="handleCurrentChange"
          :current-page.sync="pagerOne.currentPage"
          :page-size="pagerOne.pageSize"
          :hide-on-single-page="false"
          layout="prev, pager, next, jumper"
          :total="pagerOne.total"
        ></el-pagination>
        <el-pagination
          v-if="flag == 2"
          @current-change="handleCurrentChangeTwo"
          :current-page.sync="pagerTwo.currentPage"
          :page-size="pagerTwo.pageSize"
          :hide-on-single-page="false"
          layout="prev, pager, next, jumper"
          :total="pagerTwo.total"
        ></el-pagination>
        <el-pagination
          v-if="flag == 3"
          @current-change="handleCurrentChangeThree"
          :current-page.sync="pagerThree.currentPage"
          :page-size="pagerThree.pageSize"
          :hide-on-single-page="false"
          layout="prev, pager, next, jumper"
          :total="pagerThree.total"
        ></el-pagination>
        <el-pagination
          v-if="flag == 4"
          @current-change="handleCurrentChangeFour"
          :current-page.sync="pagerFour.currentPage"
          :page-size="pagerFour.pageSize"
          :hide-on-single-page="false"
          layout="prev, pager, next, jumper"
          :total="pagerFour.total"
        ></el-pagination>
      </div>
      <div class="buy-list">
        <el-table
          :data="alreadyBuyList"
          stripe
          style="width: 100%"
          v-if="flag == 1"
        >
          <el-table-column prop="id" label="订单Id"> </el-table-column>
          <el-table-column prop="oriPrice" label="原价"> </el-table-column>
          <el-table-column prop="price" label="现价"> </el-table-column>
          <el-table-column prop="purchaserName" label="购买者名字">
          </el-table-column>
          <el-table-column prop="authorizationMode" label="购买模式">
          </el-table-column>
          <el-table-column prop="status" label="状态"> </el-table-column>
          <el-table-column prop="orderTime" label="订单时间"> </el-table-column>
          <el-table-column fixed="right" label="操作">
            <template slot-scope="scope" v-if="flag == 1">
              <el-button
                type="text"
                size="medium"
                @click="onLookPic(scope.row.id)"
                >查看图片</el-button
              >
            </template>
          </el-table-column>
        </el-table>
        <!-- 待审核订单 waitApproveList -->
        <el-table
          :data="waitApproveList"
          stripe
          style="width: 100%"
          v-if="flag == 2"
        >
          <el-table-column prop="id" label="订单Id"> </el-table-column>
          <el-table-column prop="oriPrice" label="原价"> </el-table-column>
          <el-table-column prop="price" label="现价"> </el-table-column>
          <el-table-column prop="authorizationMode" label="购买模式">
          </el-table-column>
          <el-table-column prop="purchaserName" label="购买者名字">
          </el-table-column>
          <el-table-column prop="status" label="状态"> </el-table-column>
          <el-table-column prop="orderTime" label="订单时间"> </el-table-column>
          <el-table-column fixed="right" label="操作">
            <template slot-scope="scope" v-if="flag == 2">
              <el-button
                type="text"
                size="medium"
                @click="onLookPic(scope.row.id)"
                >查看图片</el-button
              >
            </template>
          </el-table-column>
        </el-table>
        <!-- flag =2 待支付 -->
        <el-table :data="noPayList" stripe style="width: 100%" v-if="flag == 3">
          <el-table-column prop="id" label="订单Id"> </el-table-column>
          <el-table-column prop="oriPrice" label="原价"> </el-table-column>
          <el-table-column prop="price" label="现价"> </el-table-column>
          <el-table-column prop="authorizationMode" label="购买模式">
          </el-table-column>
          <el-table-column prop="purchaserName" label="购买者名字">
          </el-table-column>
          <el-table-column prop="status" label="状态"> </el-table-column>
          <el-table-column prop="orderTime" label="订单时间"> </el-table-column>
          <el-table-column fixed="right" label="操作">
            <template slot-scope="scope" v-if="flag == 3">
              <el-button type="text" size="medium" @click="onBuyPic(scope.row)"
                >继续支付</el-button
              >
            </template>
          </el-table-column>
        </el-table>
        <!-- processingList 支付中-->
        <el-table
          :data="processingList"
          stripe
          style="width: 100%"
          v-if="flag == 4"
        >
          <el-table-column prop="id" label="订单Id"> </el-table-column>
          <el-table-column prop="oriPrice" label="原价"> </el-table-column>
          <el-table-column prop="price" label="现价"> </el-table-column>
          <el-table-column prop="authorizationMode" label="购买模式">
          </el-table-column>
          <el-table-column prop="purchaserName" label="购买者名字">
          </el-table-column>
          <el-table-column prop="status" label="状态"> </el-table-column>
          <el-table-column prop="orderTime" label="订单时间"> </el-table-column>
          <el-table-column fixed="right" label="操作">
            <template slot-scope="scope" v-if="flag == 4">
              <el-button type="text" size="medium" @click="onBuyPic(scope.row)"
                >继续支付</el-button
              >
            </template>
          </el-table-column>
        </el-table>
      </div>
      <el-dialog
        :title="flag == 1 ? '已支付订单的图片列表' : '待审核订单的图片列表'"
        :visible.sync="orderPicShow"
        width="65%"
        :before-close="handleClose"
      >
        <div class="pic-list">
          <div class="down-btn" v-if="flag == 1">
            <el-checkbox
              @change="onAllSelected"
              v-model="allCheck"
              :indeterminate="isIndeterminate"
              >全选</el-checkbox
            >
            <el-button type="primary" size="small" @click="onDownAll"
              >下载所勾选的{{ selectedLength }}图片</el-button
            >
          </div>
          <div class="pic-down-item">
            <div class="pic-item" v-for="k in orderPicList" :key="k.id">
              <el-checkbox
                v-if="flag == 1"
                class="selected"
                v-model="k.selectedPic"
                @change="onChangeSelect"
              ></el-checkbox>
              <div
                class="pic-img"
                :style="{ backgroundImage: `url(` + k.imgURL + `)` }"
              ></div>
              <div class="pic-con">图片Id：{{ k.picId }}</div>
              <div class="pic-con">图片价格：{{ k.price }}元</div>
              <!-- <div class="pic-con">稿件作者：{{ k.author }}</div> -->
              <div class="pic-con">稿件标题：{{ k.postTitle }}</div>
              <div class="pic-con">稿件库：{{ k.storeName }}</div>
              <div class="pic-con">
                <el-button
                  size="small"
                  v-if="flag == 1"
                  type="primary"
                  @click="onDownPic(k.orderPicId)"
                  >下载图片</el-button
                >
                <el-button
                  size="small"
                  type="primary"
                  @click="onLookPost(k.postId)"
                  >查看稿件</el-button
                >
              </div>
            </div>
          </div>
        </div>
        <span slot="footer" class="dialog-footer">
          <el-button @click="orderPicShow = false">取 消</el-button>
          <el-button type="primary" @click="orderPicShow = false"
            >确 定</el-button
          >
        </span>
      </el-dialog>
      <!-- 待支付的扫码支付 -->
      <el-dialog
        title=""
        :visible.sync="scanShow"
        width="30%"
        :close-on-click-modal="false"
        :close-on-press-escape="false"
        :show-close="true"
      >
        <div class="scan">
          <div class="scan-con">
            <img :src="scanImgURL" alt="" class="scan-img" />
          </div>
        </div>
        <span slot="footer" class="dialog-footer">
          <el-button type="primary" @click="onCloseScan">关闭窗口</el-button>
        </span>
      </el-dialog>
      <!-- 选择支付方式 -->
      <el-dialog
        title="请选择支付模式"
        :visible.sync="payModelShow"
        width="30%"
        :close-on-click-modal="false"
        :close-on-press-escape="false"
        :show-close="false"
      >
        <div class="pay-model">
          <div class="name">订单Id:{{ toOrderObj.id }}</div>
          <div class="con">
            <el-radio-group v-model="modelFlag">
              <div class="item" v-if="virtualShow">
                <el-radio label="1">
                  <i>余额付:</i>
                  <span>当前余额：{{ syncInfo.credit }} 元</span>
                  <span>价格：{{ toOrderObj.price }} 元</span>
                </el-radio>
              </div>
              <div class="item" v-if="realPayShow">
                <el-radio label="2">
                  <i>扫码付:</i>
                  <span>价格：{{ toOrderObj.price }} 元</span>
                </el-radio>
              </div>
            </el-radio-group>
          </div>
        </div>
        <span slot="footer" class="dialog-footer">
          <el-button @click="onCancel">暂不支付</el-button>
          <el-button type="primary" @click="onPayOrder">继续支付</el-button>
        </span>
      </el-dialog>
    </div>
    <!-- buyPicUrl -->
    <form :action="buyPicUrl" ref="buyPicForm" method="POST" target="_blank">
      <input type="hidden" name="orderPicIds" :value="picIdsString" />
    </form>
    <Bottom></Bottom>
  </div>
</template>

<script>
var timers = [];

import RD from "@/api/RD";
import Nav from "@/components/nav.vue";

import Bottom from "@/components/bottom.vue";
export default {
  components: { Nav, Bottom },
  data: function () {
    return {
      alreadyBuyList: [], // 已支付订单列表
      noPayList: [], // 未支付订单列表
      orderPicList: [], // 订单的图片列表
      waitApproveList: [], // 待审核订单
      processingList: [],
      flag: 1,
      buyPicUrl: "",
      picIdsString: "",
      orderPicShow: false,
      allCheck: false,
      selected: [],
      selectedLength: 0,
      isIndeterminate: false, // 半选的状态
      payModelShow: false,
      virtualShow: false, //是否可以虚拟支付
      realPayShow: false, // 真实支付方式
      syncInfo: {},
      toOrderObj: {},
      modelFlag: 0,
      pagerOne: {
        currentPage: 1,
        total: 1,
        totalPage: 1,
      },
      pagerTwo: {
        currentPage: 1,
        total: 1,
        totalPage: 1,
      },
      pagerThree: {
        currentPage: 1,
        total: 1,
        totalPage: 1,
      },
      pagerFour: {
        currentPage: 1,
        total: 1,
        totalPage: 1,
      },
      scanShow: false,
      scanImgURL: "",
      orderId: "",
    };
  },
  created() {
    var thiz = this;
    thiz.getSync();
    console.log("path", thiz.$route.query);
    if (thiz.$route.query.flag) {
      thiz.flag = thiz.$route.query.flag;
    }
    this.reload();
  },
  methods: {
    getSync() {
      var thiz = this;
      RD.pure()
        .user()
        .sync()
        .then((data) => {
          this.syncInfo = data;
          thiz.virtualShow = data.noStoreRights.indexOf("CREDIT_PAY") != -1;
          if (thiz.virtualShow) {
            thiz.modelFlag = "1";
          }
          thiz.realPayShow = data.noStoreRights.indexOf("REAL_PAY") != -1;
          if (thiz.realPayShow) {
            thiz.modelFlag = "2";
          }

          if (thiz.virtualShow && thiz.realPayShow) {
            thiz.modelFlag = "1";
          }
        })
        .catch(() => {
          console.log("catch");
          this.$alert("您当前处于未登录状态, 请先登录?", "提示", {
            confirmButtonText: "确定",
            type: "warning",
          })
            .then(() => {
              this.$router.push("/picture.html");
            })
            .catch(() => {});
        });
    },
    reload() {
      var thiz = this;

      if (thiz.flag == 1) {
        RD.myPost()
          .listPayedOrder(thiz.pagerOne.currentPage, 20)
          .then((data) => {
            thiz.alreadyBuyList = data.list;
            thiz.pagerOne = data.pager;
          });
      } else if (thiz.flag == 2) {
        RD.myPost()
          .listWaitApproveOrder(thiz.pagerFour.currentPage, 20)
          .then((data) => {
            thiz.waitApproveList = data.list;
            thiz.pagerFour = data.pager;
          });
      } else if (thiz.flag == 3) {
        RD.myPost()
          .listReadyOrder(thiz.pagerTwo.currentPage, 20)
          .then((data) => {
            thiz.noPayList = data.list;
            thiz.pagerTwo = data.pager;
          });
      } else if (thiz.flag == 4) {
        RD.myPost()
          .listProcessingOrder(thiz.pagerThree.currentPage, 20)
          .then((data) => {
            thiz.processingList = data.list;
            thiz.pagerThree = data.pager;
          });
      }
      thiz.getSync();
    },
    // 切换
    onClickItem(type) {
      var thiz = this;
      thiz.flag = type;
      console.log("flag", thiz.flag);
      thiz.reload();
    },
    // 继续支付
    onBuyPic(row) {
      var thiz = this;
      thiz.payModelShow = true;
      thiz.orderId = row.id;
      console.log("row", row);
      thiz.toOrderObj = row;
    },
    onPayOrder() {
      var thiz = this;
      console.log("model", thiz.modelFlag);
      if (thiz.modelFlag == 2) {
        thiz.sureOrder();
      } else if (thiz.modelFlag == 1) {
        RD.purchaseOrder()
          .id(thiz.orderId)
          .payWithCredit()
          .then(() => {
            this.$confirm("余额支付成功", "提示", {
              confirmButtonText: "下载图片",
              cancelButtonText: "暂不下载",
              type: "success",
            })
              .then(() => {
                thiz.sameDownPic();
              })
              .catch(() => {
                thiz.payModelShow = false;
                thiz.reload();
              });
          });
      }
    },
    // 复用的下载图片
    sameDownPic() {
      var thiz = this;
      RD.purchaseOrder()
        .id(thiz.orderId)
        .listPicsOfOrder()
        .then((data) => {
          var orderIds = [];
          data.forEach((k) => {
            orderIds.push(k.orderPicId);
          });
          thiz.picIdsString = JSON.stringify(orderIds);
          thiz.buyPicUrl =
            RD.purchaseOrder().downloadBatchByOrderPicId_URL(orderIds);
          thiz.$nextTick(() => {
            thiz.$refs.buyPicForm.submit();
          });
          thiz.payModelShow = false;
          thiz.reload();
        });
    },
    onClickModel(type) {
      var thiz = this;
      thiz.modelFlag = type;
    },
    onCancel() {
      var thiz = this;
      thiz.payModelShow = false;
      thiz.reload();
    },
    // 支付订单
    sureOrder() {
      var thiz = this;
      RD.purchaseOrder()
        .id(thiz.orderId)
        .payWithMoney()
        .then((data) => {
          thiz.scanShow = true;
          thiz.scanImgURL = data;
          timers = setInterval(() => {
            thiz.checkOrder();
          }, 2000);
        });
    },
    // 扫码之后的关闭
    onCloseScan() {
      var thiz = this;
      thiz.scanShow = false;
      thiz.payModelShow = false;
      thiz.reload();
      clearInterval(timers);
    },
    // 已完成
    checkOrder() {
      var thiz = this;
      RD.pure()
        .purchaseOrder()
        .checkOrder(thiz.orderId)
        .then((data) => {
          // thiz.reload()
          // thiz.$message('已成功支付，可以继续下载图片')
          // thiz.scanShow = false
          clearInterval(timers);
          this.$confirm("已经成功支付，是否继续下载图片", "提示", {
            confirmButtonText: "下载图片",
            cancelButtonText: "稍后再说",
            type: "warning",
          })
            .then(() => {
              thiz.sameDownPic();
              thiz.scanShow = false;
            })
            .catch(() => {
              thiz.scanShow = false;
              thiz.payModelShow = false;
              thiz.reload();
            });
        })
        .catch((error) => {});
    },
    // 查看订单里的图片
    onLookPic(orderId) {
      var thiz = this;
      this.orderPicShow = true;
      RD.purchaseOrder()
        .id(orderId)
        .listPicsOfOrder()
        .then((data) => {
          data.forEach((k) => {
            k.imgURL = RD.pic().id(k.id).sImage_URL();
          });
          thiz.orderPicList = data;
        });
    },
    // 关闭
    handleClose() {
      this.orderPicShow = false;
    },
    // 下载图片
    onDownPic(id) {
      var thiz = this;
      console.log("pic", id);
      var picIds = [];
      picIds.push(id);
      thiz.picIdsString = JSON.stringify(picIds);
      console.log("picIds", picIds);
      thiz.buyPicUrl = RD.purchaseOrder().downloadBatchByOrderPicId_URL(picIds);
      thiz.$nextTick(() => {
        thiz.$refs.buyPicForm.submit();
      });
    },
    // 全选
    onAllSelected() {
      var thiz = this;
      if (thiz.allCheck) {
        var allSelected = [];
        thiz.orderPicList.forEach((k) => {
          k.selectedPic = true;
          allSelected.push(k.id);
        });
        thiz.selectedLength = allSelected.length;
        if (allSelected.length > 1) {
          thiz.hasLength = true;
        }
        thiz.selected = allSelected;
        if (thiz.selected.length == thiz.orderPicList.length) {
          thiz.allCheck = true;
          thiz.isIndeterminate = false;
        }
      } else {
        thiz.selected = [];
        thiz.isIndeterminate = false;
        thiz.selectedLength = 0;
        thiz.allCheck = false;
        thiz.orderPicList.forEach((k) => {
          k.selectedPic = false;
        });
      }
    },
    // 下载所勾选的图片
    onDownAll() {
      var thiz = this;
      if (thiz.selected.length == 0) {
        thiz.$message("请先勾选要下载的图片");
      } else {
        var selectIds = [];
        thiz.orderPicList.forEach((k) => {
          if (k.selectedPic == true) {
            selectIds.push(k.orderPicId);
          }
        });
        thiz.picIdsString = JSON.stringify(selectIds);
        thiz.buyPicUrl =
          RD.purchaseOrder().downloadBatchByOrderPicId_URL(selectIds);
        thiz.$nextTick(() => {
          thiz.$refs.buyPicForm.submit();
        });
      }
    },
    // 勾选变化
    onChangeSelect() {
      var thiz = this;
      thiz.selected = [];
      thiz.orderPicList.forEach((k) => {
        if (k.selectedPic == true) {
          thiz.selected.push(k.id);
        }
      });
      thiz.selectedLength = thiz.selected.length;
      if (thiz.selected.length >= 1) {
        thiz.hasLength = true;
      }
      if (thiz.selected.length == thiz.orderPicList.length) {
        thiz.allCheck = true;
        thiz.isIndeterminate = false;
      } else if (thiz.selected.length == 0) {
        thiz.allCheck = false;
        thiz.isIndeterminate = false;
      } else if (thiz.selected.length >= 1) {
        thiz.isIndeterminate = true;
      }
    },
    handleCurrentChange(val) {
      var thiz = this;
      thiz.reload();
      thiz.pagerOne.currentPage = val;
    },
    handleCurrentChangeTwo(val) {
      var thiz = this;
      thiz.reload();
      thiz.pagerTwo.currentPage = val;
    },
    handleCurrentChangeThree(val) {
      var thiz = this;
      thiz.reload();
      thiz.pagerThree.currentPage = val;
    },
    handleCurrentChangeFour(val) {
      var thiz = this;
      thiz.reload();
      thiz.pagerFour.currentPage = val;
    },
    // 图片列表的查看稿件
    onLookPost(postId) {
      if (postId == undefined) {
        this.$message("没有稿件ID");
        this.$router.push("/index.html");
      } else {
        let routerUrl = this.$router.resolve({
          path: "/post.html",
          query: { id: postId },
        });
        window.open(routerUrl.href, "_blank");
      }
    },
  },
};
</script>

<style lang="less" scoped>
.whole {
  .buy-con {
    width: 120rem;
    margin: 0 auto;
    min-height: calc(100vh - 48.9rem);
    background-color: #f1f1f1;
    .buy-title {
      height: 5rem;
      line-height: 5rem;
      text-align: left;
      font-size: 1.6rem;
      margin-left: 0.5rem;
      display: flex;
      .buy-item {
        width: 12rem;
        height: 3rem;
        line-height: 3rem;
        text-align: center;
        border-radius: 0.5rem;
        margin-top: 1rem;
        border: 1px solid #ccc;
        margin-right: 2rem;
      }
      .currenred {
        color: red;
      }
    }
    .pager-container {
      text-align: right;
    }
  }
  .pic-list {
    height: 58vh;
    overflow-y: auto;
    .down-btn {
      text-align: left;
      height: 3rem;
      line-height: 3rem;
      .el-button {
        margin-left: 1rem;
      }
    }
    .pic-down-item {
      display: flex;
      flex-wrap: wrap;
      margin-top: 3rem;
      .pic-item {
        width: 27rem;
        height: 30rem;
        text-align: left;
        border: 1px solid #ccc;
        font-size: 1.6rem;
        position: relative;
        margin-right: 2rem;
        margin-bottom: 2rem;
        .selected {
          position: absolute;
          left: 0rem;
          top: -2rem;
        }
        .pic-img {
          width: 100%;
          height: 53%;
          background: no-repeat 50% / cover;
        }
        .pic-con {
          margin-left: 2px;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
          word-break: break-all;
        }
      }
    }
  }
  .pay-model {
    height: 10vh;
    .name {
      line-height: 4rem;
      height: 3rem;
      text-align: left;
      font-size: 1.6rem;
    }
    .con {
      height: 100%;
      font-size: 1.6rem;
      .item {
        flex: 1;
        height: 15%;
        margin-top: 1rem;
        text-align: left;
        span {
          margin-right: 1rem;
        }
        i {
          font-style: normal;
          color: red;
          margin-right: 1rem;
        }
      }
      .red {
        border: 1px solid red;
      }
    }
  }
  .scan {
    .scan-con {
      width: 20rem;
      height: 20rem;
      margin: 0 auto;
      img {
        width: 100%;
        height: 100%;
      }
    }
  }
}
</style>